<template>
  <div>
    <div class="header">
      <img @click="go" src="../assets/z1.png" class="img" alt="" />
      <div v-if="userinfo" class="user">
        <img :src="userinfo.picture" alt="" />
        <div>
          <div class="l">
            {{ userinfo.wx_name }}
            <span v-if="type == 1 || type == 5">销售</span>
            <span v-if="type == 2">会员</span>
            <span v-if="type == 3">渠道</span>
            <span v-if="type == 4">会员</span>
          </div>
          <div class="r">学习惠id：{{ userinfo.id }}</div>
        </div>
      </div>
      <div v-else class="notlogin">
        <img src="../assets/tx.png" alt="" />
        <div>
          <div class="l">您好！您还未授权登录</div>
          <div class="r" @click="user">授权登录</div>
        </div>
      </div>
    </div>
    <div class="tbr">
      <div class="tablist">
        <div>
          <div class="r">{{ forward }}</div>
          <div class="l">我的转发</div>
        </div>
        <div @click="collect">
          <div class="r">{{ collection }}</div>
          <div class="l">我的收藏</div>
        </div>
      </div>
      <div class="schedule">
        <span>我的信息</span>
        <van-progress
          class="progress"
          :percentage="num"
          :show-pivot="false"
          stroke-width="0.24rem"
          color="#FFB200"
        />
      </div>
    </div>
    <div class="list">
      <router-link to="/myuser" class="item">
        <div class="l">
          <img src="../assets/zhanghao-2.png" alt="" />
          我的信息
        </div>
        <img src="../assets//arrow.png" class="r" />
      </router-link>
      <router-link to="/client" class="item" v-if="type == 3">
        <div class="l">
          <img src="../assets/zhanghao-2.png" alt="" />
          我的客户
        </div>
        <img src="../assets//arrow.png" class="r" />
      </router-link>
      <router-link to="/myfeedback" class="item">
        <div class="l">
          <img src="../assets/qiye-2.png" alt="" />
          我的提问
        </div>
        <img src="../assets//arrow.png" class="r" />
      </router-link>
    </div>
    <div class="list" style="top: -0.5rem">
      <router-link to="/suggest" class="item">
        <div class="l">
          <img src="../assets/jy.png" alt="" />
          投诉和建议
        </div>
        <img src="../assets//arrow.png" class="r" />
      </router-link>
      <router-link to="/contact" class="item">
        <div class="l">
          <img src="../assets/dh.jpg" alt="" />
          联系我们
        </div>
        <img src="../assets//arrow.png" class="r" />
      </router-link>
      <router-link to="/regards" class="item">
        <div class="l">
          <img src="../assets/guanyu.png" alt="" />
          关于沪惠学
        </div>
        <img src="../assets//arrow.png" class="r" />
      </router-link>
      <div class="item" @click="gosq">
        <div class="l">
          <img src="../assets/zz.jpg" alt="" />
          申请成为渠道
        </div>
        <img src="../assets//arrow.png" class="r" />
      </div>
      <div class="item" @click="confirm">
        <div class="l">
          <img src="../assets/tuicu.jpg" alt="" />
          安全退出
        </div>
        <img src="../assets//arrow.png" class="r" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      Invited: "",
      collection: 0,
      forward: 0,
      type: "",
      num: 0,
      sss: require("../assets/error.png"),
    };
  },
  methods: {
    ...mapActions([
      "getinformation",
      "getinvitationInfo",
      "getinformation_up",
      "getSignPackage",
      "getWxLogin",
      "getforward",
    ]),
    collect() {
      this.$router.push("/collect");
    },
    user() {
      var url =
        "https://xuexh.zpt.udao.sh.cn/Index/wx_login?scope=snsapi_userinfo&request_url=https://huhuixue.zpt.udao.sh.cn/user";
      var redirect_uri = encodeURIComponent(url);
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxba0f904e25a24e03&redirect_uri=" +
        redirect_uri +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
    go() {
      this.$router.push("/");
      // this.$router.go(-1);
    },
    gosq() {
      if (this.userinfo.type == 2) {
        this.$router.push("/agreement");
      } else {
        this.$router.push("/applyfor");
      }
    },
    confirm() {
      this.$dialog
        .confirm({
          title: "",
          message: "是否退出",
          confirmButtonColor: "#000",
          confirmButtonText: "退出",
        })
        .then(() => {
          // on confirm
          this.collection = 0;
          this.forward = 0;
          this.num = 0;
          this.$store.commit("SET_USERINFO", "");
        })
        .catch(() => {
          // on cancel
        });
    },
    getuser() {
      if (this.userinfo) {
        this.getinformation({
          member_id: this.userinfo.id,
        }).then((res) => {
          if (res.SuccessCode == 200) {
            this.collection = res.ResponseBody.collection;
            this.forward = res.ResponseBody.forward;
            this.$store.commit("SET_USERINFOTYPE", res.ResponseBody.info.type);
            this.type = res.ResponseBody.info.type;
            let num = 0;
            if (res.ResponseBody.info.name) {
              // +1
              num += 16.666666666666668;
            }
            if (res.ResponseBody.info.sex) {
              // +1
              num += 16.666666666666668;
            }
            if (res.ResponseBody.info.phone) {
              // +1
              num += 16.666666666666668;
            }
            if (res.ResponseBody.info.wx) {
              // +1
              num += 16.666666666666668;
            }
            if (res.ResponseBody.info.id) {
              // +1
              num += 16.666666666666668;
            }
            if (res.ResponseBody.info.type) {
              // +1
              num += 16.666666666666668;
            }
            this.num = num.toFixed(0);
            this.getinvitationInfo({
              invitation:
                res.ResponseBody.info.invited ||
                res.ResponseBody.info.sale_invitation,
            }).then((resq) => {
              if (
                res.ResponseBody.info.type == 1 ||
                res.ResponseBody.info.type == 5
              ) {
                // 销售默认显示自己  // 接口报错执行201//原因应该老账号原因，
                if (resq.SuccessCode == 201) {
                  this.$store.commit(
                    "SET_INVITATIONINIF",
                    res.ResponseBody.info
                  );
                } else {
                  this.$store.commit(
                    "SET_INVITATIONINIF",
                    res.ResponseBody.info
                  );
                }
              } else {
                //判断会员 渠道 是否有邀请码
                if (!res.ResponseBody.info.invited) {
                  // 没有就执行这里，显示默认销售
                  if (res.ResponseBody.info.sale_invitation) {
                    this.$store.commit("SET_INVITATIONINIF", resq.ResponseBody);
                  } else {
                    this.getdefaultSale({}).then((sss) => {
                      this.$store.commit(
                        "SET_INVITATIONINIF",
                        sss.ResponseBody
                      );
                      this.getinformation_up({
                        id: this.userinfo.id,
                        sale_invitation: sss.ResponseBody.invitation,
                      });
                    });
                  }
                } else {
                  // 接口报错执行201//原因应该老账号原因，
                  if (resq.SuccessCode == 201) {
                    this.$store.commit(
                      "SET_INVITATIONINIF",
                      res.ResponseBody.info
                    );
                  } else {
                    //有邀请码执行这个
                    //判断渠道是否有上级
                    if (resq.ResponseBody.invited) {
                      this.getinvitationInfo({
                        invitation: resq.ResponseBody.invited,
                      }).then((eee) => {
                        this.$store.commit(
                          "SET_INVITATIONINIF",
                          eee.ResponseBody
                        );
                      });
                    } else {
                      this.$store.commit(
                        "SET_INVITATIONINIF",
                        resq.ResponseBody
                      );
                    }
                  }
                }
              }
            });
          }
        });
      }
    },
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
      login_path: (state) => state.login_path,
    }),
  },
  mounted() {
    let that = this;
    if (this.userinfo) {
      this.getuser();
    }
    if (this.userinfo == "") {
      if (this.$route.query.code) {
        this.getWxLogin({
          code: this.$route.query.code,
        }).then((res) => {
          if (res.SuccessCode == 200) {
            this.$store.commit("SET_USERINFO", res.ResponseBody);
            this.getuser();
            if (this.login_path == "") {
            } else {
              let is_url = this.login_path.replace(
                /https:\/\/huhuixue.zpt.udao.sh.cn/g,
                ""
              );
              this.$store.commit("SET_FALG", 2);
              this.$router.push(is_url);
            }
          } else if (res.SuccessCode == 2003) {
            this.$toast.fail(res.Message);
          } else {
            this.$store.commit("SET_WX", res.ResponseBody);
            this.$router.push("/bindPhone");
          }
        });
      } else {
        this.$dialog
          .alert({
            title: "提示",
            message: "为了更好的体验请登录",
          })
          .then(() => {
            this.user();
          });
      }
    }
  },
};
</script>

<style lang='scss' scoped>
.header {
  position: relative;
  height: 4.5rem;
  background: url("../assets/userimg.jpg") no-repeat;
  background-size: 100%;
  .img {
    position: absolute;
    top: 0.3rem;
    left: 0.33rem;
    width: 0.5rem;
    height: auto;
  }
  .user {
    display: flex;
    position: absolute;
    top: 1.01rem;
    left: 0.7rem;
    img {
      border: 7px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: 50%;
      width: 1.05rem;
      height: 1.05rem;
      margin-right: 0.24rem;
    }
    .l {
      font-size: 0.4rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
      span {
        margin-left: 0.19rem;
        display: inline-block;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        padding: 0.05rem 0.12rem;
        background-color: #007dff;
        border-radius: 0.16rem 0rem 0.16rem 0rem;
      }
    }
    .r {
      font-size: 0.3rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .notlogin {
    position: absolute;
    display: flex;
    top: 1.01rem;
    left: 0.7rem;
    img {
      margin-right: 0.24rem;
      width: 1.14rem;
      height: 1.14rem;
      border: 0.1rem solid #76a6e5;
      border-radius: 50%;
    }
    .l {
      margin-top: 0.1rem;
      margin-bottom: 0.15rem;
      font-size: 0.3rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .r {
      text-align: center;
      width: 1.96rem;
      height: 0.59rem;
      background: #1863b2;
      border-radius: 0.16rem;
      border: 0.02rem solid #0c55a1;
      font-size: 0.3rem;
      line-height: 0.59rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.tbr {
  position: relative;
  top: -1.3rem;
  margin-left: 0.22rem;
  // margin-bottom: -1rem;
  width: 7.1rem;
  height: 2.98rem;
  background: #ffffff;
  box-shadow: 0rem 0.05rem 0.12rem 0rem rgba(156, 156, 156, 0.23);
  border-radius: 0.1rem;
  .tablist {
    display: flex;
    justify-content: space-around;
    .r {
      margin-top: 0.53rem;
      text-align: center;
      font-size: 0.7rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0019ff;
    }
    .l {
      text-align: center;
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141f33;
    }
  }
  .schedule {
    margin-top: 0.4rem;
    span {
      margin-right: 0.2rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #bcbcbc;
    }
    .progress {
      width: 5rem;
    }
    padding: 0 0.41rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
}
.list {
  position: relative;
  top: -0.7rem;
  overflow: hidden;
  width: 7.1rem;
  margin-left: 0.2rem;
  background: #ffffff;
  box-shadow: 0rem 0.05rem 0.12rem 0rem rgba(156, 156, 156, 0.23);
  border-radius: 0.1rem;
  .item {
    padding: 0 0.38rem;
    margin: 0.2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.78rem;
    background: #ffffff;
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141f33;

    .l {
      img {
        width: 0.37rem;
        margin-right: 0.15rem;
        height: auto;
        vertical-align: middle;
        transform: translateY(-0.03rem);
      }
    }
    .r {
      width: 0.3rem;
      height: auto;
    }
  }
}
</style>